import { Injectable, inject, signal } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { DefaultService } from '../../utils/api/api/default.service';
import { User } from '../../utils/api/model/user';
import { HelpersService } from './helpers.service';
import { Router } from '@angular/router';
import { LoginResponse } from '../../utils/api';
import { PrefixLangPipe } from '../../utils/pipes';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    // injections
    defaultService = inject(DefaultService);
    helpersService = inject(HelpersService);
    router = inject(Router);
    prefixLangPipe = inject(PrefixLangPipe);

    // signals
    user = signal<User>(null);
    authData = signal<{ username: string; password: string }>(null);
    mfaSetup = signal<{ url: string; secret: string }>(null);
    localStorageOverviewConfigPerSubscriptionId = signal<Record<string, Record<string, any>>>(null);

    public async init() {
        this.initLocalStorageOverviewConfigPerSubscriptionId();
    }

    public refreshUser() {
        return lastValueFrom(this.defaultService.usersGetUserinfo()).then((res) => this.user.set(res));
    }

    initLocalStorageOverviewConfigPerSubscriptionId() {
        const result = localStorage.getItem('localStorageOverviewConfigPerSubscriptionId');
        if (result) {
            this.localStorageOverviewConfigPerSubscriptionId.set(JSON.parse(result));
        } else if (this.user()?.subscriptionId) {
            this.localStorageOverviewConfigPerSubscriptionId.set({ [this.user().subscriptionId]: {} });
        }
    }

    public logout(): void {
        // this.defaultService.usersLogout().subscribe((next) => {
        //     window.location.href = 'login';
        // });
        window.location.href = `fuseAction.cfm?module=users&subpage=logout&sid=${this.user().subscriptionId}`;
    }

    public onUnauthorized(): void {
        let to = `${window.location.pathname.replace(/^\/+/g, '')}`;
        if (to) {
            to = `?backTo=${to}`;
        } else to = '';
        window.location.href = `login${to}`;
    }

    public afterAuthCall(next: LoginResponse, authData: { username: string; password: string }): void {
        this.authData.set(authData);
        this.mfaSetup.set(next.mfaSetup);
        switch (next.result) {
            case 'login':
                this.loginRedirect();
                break;
            case 'merge-in-progress':
                this.router.navigate([this.prefixLangPipe.transform('auth/merge-in-progress')], {
                    queryParamsHandling: 'preserve'
                });
                break;
            case 'setup-mfa':
                this.router.navigate([this.prefixLangPipe.transform('auth/setup-multifactor-authentication')], {
                    queryParamsHandling: 'preserve'
                });
                break;
            case 'prompt-mfa':
                this.router.navigate([this.prefixLangPipe.transform('auth/multifactor-authentication')], {
                    queryParamsHandling: 'preserve'
                });
                break;
        }
    }

    public loginRedirect(): void {
        window.location.href = 'callback/login';
    }

    public getBackToParam(): string {
        let to = this.helpersService.getParam('backTo') || this.helpersService.getParam('backto') || '';
        to = to.replace(/^\W*/g, '');
        to = `/${to}`;
        if (to === '/') to = null;
        return to;
    }

    public setLocalStorageOverviewConfigPerSubscriptionId(key: `assistantOverview_${string}`, value: any) {
        if (!this.user().subscriptionId) return;
        const result = this.localStorageOverviewConfigPerSubscriptionId();
        result[this.user().subscriptionId] = { ...result[this.user().subscriptionId], [key]: value };
        this.localStorageOverviewConfigPerSubscriptionId.set(result);
        localStorage.setItem('localStorageOverviewConfigPerSubscriptionId', JSON.stringify(result));
    }
}
